<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Müşteri Adı"
            label-for="customer"
          >
            <b-form-input
              v-model="dataItem.customer"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Görüşme No"
            label-for="interview_number"
          >
            <b-form-input
              v-model="dataItem.interview_number"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Proje"
            label-for="project"
          >
            <b-form-input
              v-model="dataItem.project"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Gayrimenkul Tipi"
            label-for="type"
          >
            <b-form-input
              v-model="dataItem.type"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Blok"
            label-for="block"
          >
            <b-form-input
              v-model="dataItem.block"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Kapı No"
            label-for="number"
          >
            <b-form-input
              v-model="dataItem.number"
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Liste Fiyatı"
            label-for="listprice"
          >
            <cleave
              id="request_price"
              v-model="dataItem.listprice"
              class="form-control"
              :raw="true"
              :options="options.price"
              placeholder="Liste Fiyatı"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Satış Fiyatı"
            label-for="saleprice"
          >
            <cleave
              id="request_price"
              v-model="dataItem.saleprice"
              class="form-control"
              :raw="true"
              :options="options.price"
              placeholder="Satış Fiyatı"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-button
      variant="danger"
      :disabled="submitStatus"
      @click="saveData"
    >
      Kaydet
    </b-button>
  </div>
</template>

<script>
import {
  BCard, BFormGroup, BFormInput, BRow, BCol, BButton,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'

export default {
  name: 'Edit',
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    Cleave,
  },
  data() {
    return {
      submitStatus: null,
      options: {
        price: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['realtySales/dataItem']
    },
    saveStatus() {
      return this.$store.getters['realtySales/dataSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getData()
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    getData() {
      this.$store.dispatch('realtySales/getDataItem', this.$route.params.id)
    },
    saveData() {
      this.submitStatus = true
      this.$store.dispatch('realtySales/saveData', this.dataItem)
    },
  },
}
</script>

<style scoped>

</style>
